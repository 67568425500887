import React from 'react';
import * as Sentry from '@sentry/react';
import { router } from '@inertiajs/react';

interface SentryErrorBoundaryProps {
  children: React.ReactNode;
}

const SentryErrorBoundary: React.FC<SentryErrorBoundaryProps> = ({ children }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <div className="flex min-h-screen items-center justify-center p-4">
          <div className="w-full max-w-md rounded-lg border border-red-100 p-6 shadow-md">

            <h2 className="mt-4 text-center text-xl font-semibold text-foreground">
              Something went wrong
            </h2>

            <p className="mt-2 text-center text-sm text-foreground/70">
                {process.env.NODE_ENV !== 'production' && (
                    error.message || "We encountered an error while rendering this page."
             )}
             Our engineers have been notified and are working on a fix.
            </p>

            {process.env.NODE_ENV !== 'production' && (
              <details className="mt-4 text-xs">
                <summary className="cursor-pointer font-medium text-primary">
                  Technical Details
                </summary>
                <pre className="mt-2 max-h-48 overflow-auto whitespace-pre-wrap rounded bg-gray-100 text-foreground p-2 text-xs">
                  {error.toString()}
                  {componentStack}
                </pre>
              </details>
            )}

            <div className="mt-6 flex gap-3">
              <button
                onClick={resetError}
                className="flex-1 rounded bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Try Again
              </button>

              <button
                onClick={() => window.location.href = '/'}
                className="flex-1 rounded bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
              >
                Go Home
              </button>
            </div>

          </div>
        </div>
      )}
      beforeCapture={(scope) => {
        scope.setTag("location", window.location.href);
        // Add additional context if needed
        if (document.referrer) {
          scope.setContext("referrer", { url: document.referrer });
        }
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default SentryErrorBoundary;
