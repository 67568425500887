import { CookieNotice } from 'react-cookienotice'
import 'react-cookienotice/style'
import ErrorBoundary from './components/error-boundary';

export default function AppEntry({children}: { children: React.ReactNode }) {
    return (
        <ErrorBoundary>
                {children}
            <CookieNotice />
        </ErrorBoundary>
    );
}